import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function Latrobe() {
  return (
    <Layout pageTitle="Latrobe">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Looking Forward Latrobe - USI Projects</title>
        <meta name="description" content="Looking Forward Latrobe is a short 6-week visioning and co-design project based in the Latrobe Valley in Victoria, Australia."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

        <section class="text-gray-700 body-font heading-container-latrobe">
          <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Latrobe</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Latrobe Valley Health Assembly</p>
              </div>
          </section>
        </div>
      </section>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                Looking Forward Latrobe is a short 6-week visioning and co-design project based in the Latrobe 
                Valley in Victoria, Australia. USI worked with the Latrobe Health Assembly, the community and 
                stakeholders to discover ways in which wellbeing could be embedded into the future of the valley.<br/><br/>
                Whilst tackling key opportunity areas; changing perspectives, fostering pride and improving connections 
                for all those who live, work and play in the valley.
                  </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p 
                  class="my-4 text-gray-500 leading-relaxed mt-0">Community<br/>Regeneration<br/>Active Citizenship<br/>Policy & Strategy
                </p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">
                  Citizen Engagement<br/>Concept Development<br/>Illustration<br/>Policy Recomendations<br/>Creative Workshops<br/>Thematics<br/>Establishing Principles and Frameworks</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true" class="md:p-16 md:m-16">
          <img src="../img/projects/latrobe-picture-frame.webp"/>
      </div>

      <div class="flex flex-wrap md:p-16 md:m-16 px-5">
        <div class="lg:w-3/6 px-2">
          <img src="../img/projects/latrobe-mural.webp"/>
          <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
          The Latrobe Health Assembly are now looking to tackle several of the project concepts, alongside support from USI and through further 
          community engagement.  The wellbeing principles and indicators have been adopted by the health assembly to inform all 
          future place-baed health projects in the region
          </p>
        </div>
        <div class="lg:w-3/6 px-2">
          <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading py-6">OVER 170 PEOPLE ENGAGED</h3>
          <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
            Over 170 people were engaged with; from interviews to workshops and online surveys 
            all tackled remotely due to the Covid-19 restrictions on travel. This feedback highlighted 
            the very best of the Latrobe Valley; from the diversity of people, to the variety of flora and 
            fauna and the continued innovation across the area. Co-creating with stakeholders and the community 
            highlighted 7 key focus areas and 4 wellbeing principles; which can be seen in more detail on the project website.
          </p>
        </div>
      </div>

      <div class="flex flex-wrap md:p-16 md:m-16 items-center px-5">
        <div class="lg:w-1/4 px-2 text-center">
          <img src="../img/projects/latrobe-grid1.webp"/>
          <h3 class="text-xl md:text-2xl font-bold pb-6">Creative and Curious</h3>
        </div>
        <div class="lg:w-1/4 px-2 text-center">
          <img src="../img/projects/latrobe-grid2.webp"/>
          <h3 class="text-xl md:text-2xl font-bold pb-6">Vibrant and Connected</h3>
        </div>
        <div class="lg:w-1/4 px-2 text-center">
          <img src="../img/projects/latrobe-grid3.webp"/>
          <h3 class="text-xl md:text-2xl font-bold pb-6">Physical and Mental</h3>
        </div>
        <div class="lg:w-1/4 px-2 text-center">
          <img src="../img/projects/latrobe-grid4.webp"/>
          <h3 class="text-xl md:text-2xl font-bold pb-6">Equal and Inclusive</h3>
        </div>
      </div>

      <div class="flex flex-wrap md:p-16 md:m-16 items-center px-5">
        <div class="lg:w-2/4 px-2 text-center">
          <img src="../img/projects/latrobe-sketch1.webp"/>
        </div>
        <div class="lg:w-2/4 px-2 text-center">
          <img src="../img/projects/latrobe-sketch2.webp"/>
        </div>
      </div>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-green-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../healthyplaces" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
